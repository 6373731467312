angular.module('app')
    .filter('bookingTime', ['$filter', 'moment', function ($filter, moment) {
        return function (dateTime, format, defaultValue) {

            if (angular.isUndefined(dateTime)) return '';

            if (dateTime.indexOf(' at ') >= 0) {
                return dateTime.substr(dateTime.indexOf(' at ')+ 4, dateTime.length);
            }

            return moment(dateTime, 'DD/MM/YYYY h:mm A').format('h:mm A');

        };
    }]);